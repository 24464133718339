


































import {Vue,Component} from  'vue-property-decorator'
import {State,Action} from 'vuex-class';
import Loading from '@/entidades/Sistema/Loading';
import { ObtenerPartePendiente, ParteModelConsulta, ParteQueryParametro } from '@/entidades/Plantacion/Partes/Consulta/ParteModelConsulta';
import Alerta from '@/entidades/Sistema/Alerta';
import { TableBtnModel } from '@/entidades/TableBtnModel';
const namespaceAuth = "authModule";
@Component({
    components:{
        'tabla' : () =>import('@/components/TablaReutilizable.vue'),
        'editarParte' :() => import('@/components/Forestal/Parte/EditarParteRechazado.vue')
    }
})
export default class ParteRechazadoView extends Vue
{
    @State('usuarioId', {namespace:namespaceAuth} ) usuarioId!: string;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    listadoPartes:Array<ParteModelConsulta> =[];
       headers:any[]= 
    [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value:'fecha'
        },
        {
          text: '# Parte',
          align: 'start',
          sortable: false,
          value:'parteIdCustomer'
        },
        {
          text: 'Hacienda',
          align: 'start',
          sortable: false,
          value: 'haciendaName'
        },
        {
          text: 'Mayordomo',
          align: 'start',
          sortable: false,
          value:'mayordomo'
        },
         {
          text: 'Rechazo',
          align: 'start',
          sortable: false,
          value:'usuarioRechazo',
        },
         {
          text: 'Motivo',
          align: 'start',
          sortable: false,
          value:'motivo',
        },
        
        { text: 'Acciones', align:'center', value: 'acciones', sortable: false },       
    ];
    botones:TableBtnModel[]=
    [
        {
            icon:'fas fa-edit',
            label:'',
            action:'modificarParte',
            tooltip:'Editar Parte',
            necesitaIndex:false
        }
    ];
    itemTmp:ParteModelConsulta| undefined = undefined;

    componenteName:string="";
    modal:boolean=false;


    modificarParte(item:ParteModelConsulta)
    {
        this.itemTmp = item;
        this.componenteName = "editarParte";
        this.modal = true;
    }

  async ObtenerParte()
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion"));
            var parametroFiltro = new ParteQueryParametro();
            parametroFiltro.usuarioId = this.usuarioId;
            parametroFiltro.tipo = true;
            let rsp = await ObtenerPartePendiente(parametroFiltro);
            if(rsp.isSuccess == true)
            {
                this.listadoPartes = rsp.isResult;
            }
            else
            {
                 this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",1000));
                 this.listadoPartes =[];
            }
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    limpiar()
    {
        this.modal = false;
        this.componenteName = "";
        this.itemTmp = undefined;
        this.ObtenerParte();
    }

    get propiedades()
    {
        if(this.componenteName === "editarParte")
        {
            return {'item':this.itemTmp}
        }
    }

    mounted() {
        this.ObtenerParte();   
    }
}
